import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    padding: "20px 60px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    //lineHeight: 1.2,
    margin: 30
  },
  description: {
    textAlign: "left",
    fontSize: "1.25rem",
    lineHeight: 1.25,
    marginTop: 20,
    marginBottom: 20
  },
  vehicleListContainer: {
    marginTop: 20,
    filter: "grayscale(100%)"
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  logoImg: {
    maxWidth: "100%",
    maxHeight: 80
  },
}))
