import evaluateIcon from "../../../../media/services/evaluate-icon.png"
import deployIcon from "../../../../media/services/deploy-icon.png"
import defineIcon from "../../../../media/services/define-icon.png"

export const technologyImplementationList = [
  {
    icon: evaluateIcon,
    description: "Evaluate and choose the right vendors based on detailed criteria using our vendor evaluation scorecard"
  },
  {
    icon: deployIcon,
    description: "Deploy and configure major portfolio operating systems such as Blackrock Aladdin, Charles River, and many more"
  },
  {
    icon: defineIcon,
    description: "Define and document policies and procedures, and provide training to ensure user adoption of the system"
  }
]
