import * as React from "react"
import { Typography } from "@material-ui/core"

import implementationLifecycleImg from "../../../../media/services/implementation-lifecycle-infograph.png"

import { useStyles } from "./styles"

const title = "Implementation Lifecycle"

const ImplementationLifecycle = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.imageWrapper}>
          <img
            src={implementationLifecycleImg}
            className={classes.backgroundImg}
          />
        </div>
      </div>
    </div>
  )
}

export default ImplementationLifecycle
