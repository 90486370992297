import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    lineHeight: 1.2,
    //color: "#2d65b2"
  },
  imageWrapper: {
    maxWidth: 1200,
    maxWidth: "100%",
    [theme.breakpoints.up("xl")]: {
      padding: "40px 100px 0 100px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "60px 80px 0 80px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "40px 40px 0 40px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0 0 0",
    },
  },
  backgroundImg: {
    width: "100%",
  },
}))
