import Aladdin from "../../../../media/services/vendor-logos/Aladdin.svg"
import Allvue from "../../../../media/services/vendor-logos/Allvue.png"
import AMBest from "../../../../media/services/vendor-logos/AM Best.png"
import BISAM from "../../../../media/services/vendor-logos/BISAM.png"
import CDOSuite from "../../../../media/services/vendor-logos/CDO Suite.jpg"
import CharlesRiver from "../../../../media/services/vendor-logos/Charles River.jpg"
import Clearstructure from "../../../../media/services/vendor-logos/Clearstructure.png"
import Cortland from "../../../../media/services/vendor-logos/Cortland.png"
import Curium from "../../../../media/services/vendor-logos/Curium.png"
import DBRS from "../../../../media/services/vendor-logos/DBRS.png"
import Deloitte from "../../../../media/services/vendor-logos/Deloitte.png"
import DTCC from "../../../../media/services/vendor-logos/DTCC.png"
import DUCO from "../../../../media/services/vendor-logos/DUCO.png"
import Eagle from "../../../../media/services/vendor-logos/Eagle.png"
import eFront from "../../../../media/services/vendor-logos/eFront.png"
import EganJones from "../../../../media/services/vendor-logos/Egan-Jones.png"
import epam from "../../../../media/services/vendor-logos/epam.png"
import Factset from "../../../../media/services/vendor-logos/Factset.png"
import Finastra from "../../../../media/services/vendor-logos/Finastra.png"
import Finra from "../../../../media/services/vendor-logos/Finra.png"
import FIS from "../../../../media/services/vendor-logos/FIS.png"
import Fitch from "../../../../media/services/vendor-logos/Fitch.png"
import Fundcount from "../../../../media/services/vendor-logos/Fundcount.png"
import FXConnect from "../../../../media/services/vendor-logos/FX Connect.png"
import IHSMarkit from "../../../../media/services/vendor-logos/IHS Markit.png"
import Kroll from "../../../../media/services/vendor-logos/Kroll.png"
import MarketAxess from "../../../../media/services/vendor-logos/Market Axess.png"
import Moodys from "../../../../media/services/vendor-logos/Moodys.png"
import NAIC from "../../../../media/services/vendor-logos/NAIC.png"
import Omgeo from "../../../../media/services/vendor-logos/Omgeo.png"
import Reuters from "../../../../media/services/vendor-logos/Reuters.png"
import RIMES from "../../../../media/services/vendor-logos/RIMES.png"
import SP from "../../../../media/services/vendor-logos/S&P.png"
import Simcorp from "../../../../media/services/vendor-logos/Simcorp.png"
import SSC from "../../../../media/services/vendor-logos/SS&C.png"
import StatPro from "../../../../media/services/vendor-logos/StatPro.png"
import SWIFT from "../../../../media/services/vendor-logos/SWIFT.png"
import Tradeweb from "../../../../media/services/vendor-logos/Tradeweb.png"
import Virtus from "../../../../media/services/vendor-logos/Virtus.png"

export const vendors = [
  {
    title: "Aladdin",
    image: Aladdin,
  },
  {
    title: "Allvue",
    image: Allvue,
  },
  {
    title: "AM Best",
    image: AMBest,
  },
  {
    title: "BISAM",
    image: BISAM,
  },
  {
    title: "CDO Suite",
    image: CDOSuite,
  },
  {
    title: "Charles River",
    image: CharlesRiver,
  },
  {
    title: "Clearstructure",
    image: Clearstructure,
  },
  {
    title: "Cortland",
    image: Cortland,
  },
  {
    title: "Curium",
    image: Curium,
  },
  {
    title: "DBRS",
    image: DBRS,
  },
  {
    title: "Deloitte",
    image: Deloitte,
  },
  {
    title: "DTCC",
    image: DTCC,
  },
  {
    title: "DUCO",
    image: DUCO,
  },
  {
    title: "Eagle",
    image: Eagle,
  },
  {
    title: "eFront",
    image: eFront,
  },
  {
    title: "Egan-Jones",
    image: EganJones,
  },
  {
    title: "epam",
    image: epam,
  },
  {
    title: "Factset",
    image: Factset,
  },
  {
    title: "Finastra",
    image: Finastra,
  },
  {
    title: "Finra",
    image: Finra,
  },
  {
    title: "FIS",
    image: FIS,
  },
  {
    title: "Fitch",
    image: Fitch,
  },
  {
    title: "Fundcount",
    image: Fundcount,
  },
  {
    title: "FX Connect",
    image: FXConnect,
  },
  {
    title: "IHS Markit",
    image: IHSMarkit,
  },
  {
    title: "Kroll",
    image: Kroll,
  },
  {
    title: "Market Axess",
    image: MarketAxess,
  },
  {
    title: "Moodys",
    image: Moodys,
  },
  {
    title: "NAIC",
    image: NAIC,
  },
  {
    title: "Omgeo",
    image: Omgeo,
  },
  {
    title: "Reuters",
    image: Reuters,
  },
  {
    title: "RIMES",
    image: RIMES,
  },
  {
    title: "S&P",
    image: SP,
  },
  {
    title: "Simcorp",
    image: Simcorp,
  },
  {
    title: "SS&C",
    image: SSC,
  },
  {
    title: "StatPro",
    image: StatPro,
  },
  {
    title: "SWIFT",
    image: SWIFT,
  },
  {
    title: "Tradeweb",
    image: Tradeweb,
  },
  {
    title: "Virtus",
    image: Virtus,
  },
]
