import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "none",
    padding: 20,
    marginBottom: 60
  },
  iconWrapper: {
    height: 110,
    marginBottom: 10,
    
  },
  icon: {
    width: 100,
    height: 100,
  },
  title: {
    color: "#000",
    lineHeight: 1.2,
    textAlign: "center",
    marginBottom: 10,
  },
  description: {
    color: "#000",
    lineHeight: 1.4,
    fontSize: "1.25rem",
    textAlign: "left",
    maxWidth: 350
    
  },
}))
