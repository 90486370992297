import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import SubHero from "../../components/SubHero"
import Introduction from "../../components/Introduction"
import TechnologyImplementationServices from "../../sections/services/TechnologyImplementationServices"
import ImplementationLifecycle from "../../sections/services/ImplementationLifecycle"
import Vendors from "../../sections/services/Vendors"
import LearnHow from "../../components/LearnHow"
import NextService from "../../sections/services/NextService"

import technologyImplementationBgUrl from "../../../media/technology-implementation-banner-overlay.jpg"

const technologyImplementationTitle = "Technology Implementation Services"
const technologyImplementationDescription = ""
const introductionTitle = "Technology solutions driven by your business objectives"
const introductionPara1 = "Technology implementation and conversion can be a major distraction for your team. From software selection to installation, configuration, and integration with other systems, it's rarely a straightforward project. Our implementation teams are comprised of seasoned financial industry professionals with experience across the full investment lifecycle to provide business systems analysis, data migration, and project management so your team can focus on your core business."
const introductionPara2 = ""
const para = [
  introductionPara1,
  introductionPara2
]
const learnHowTitle = "Read the case study"
const learnHowDescription = "Learn how the investment management division of an insurance company saved $1.5 million annually when it teamed with Grandview to internalize investment operations."
const learnHowLink = "case-study/system-implementation-and-organization-planning"

const TechnologyImplementationPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title={technologyImplementationTitle} />
    <SubHero
      backgroundImage={technologyImplementationBgUrl}
      title={technologyImplementationTitle}
      description={technologyImplementationDescription}
    />
    <Introduction
      title={introductionTitle}
      introduction={para}
    />
    <TechnologyImplementationServices />
    <ImplementationLifecycle />
    <Vendors />
    <LearnHow
      title={learnHowTitle}
      description={learnHowDescription}
      to={learnHowLink}
    />
    <NextService
      text="Next, learn about our Systems Integration services"
      link="services/systems-integration"
    />
  </Layout>
)

export default TechnologyImplementationPage
