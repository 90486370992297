import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
  },
  container: {
    maxWidth: 1440,
    display: "flex",
    justifyContent: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",  
  },
}))
