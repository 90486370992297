import * as React from "react"
import { Grid } from "@material-ui/core"

import CardWithCircleIcon from "../../../components/CardWithCircleIcon"
import { technologyImplementationList } from "./data"
import { useStyles } from "./styles"

const TechnologyImplementationServices = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={10} className={classes.container}>
      {technologyImplementationList.map((item, index) => (
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}
          key={`technology-implementation-item-${index}`}
          className={classes.item}
        >
          <CardWithCircleIcon
            icon={item.icon}
            description={item.description}
          />
        </Grid>
      ))}
      </Grid>
    </div>
  )
}

export default TechnologyImplementationServices
