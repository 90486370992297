import * as React from "react"
import { navigate } from "@reach/router"
import { Typography } from "@material-ui/core"

import { useStyles } from "./styles"

interface CardWithCircleIconProps {
  title?: string,
  link?: string,
  description?: string,
  icon?: any,
}

const CardWithCircleIcon = ({ link = "", title, icon, description }: CardWithCircleIconProps) => {
  const classes = useStyles()

  return (
    <div
      onClick={() => link && navigate(link)}
      className={classes.root}
    >
      <div className={classes.iconWrapper}>
        <img src={icon} className={classes.icon} />
      </div>
      <Typography variant="subtitle2" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.description}>
        {description}
      </Typography>
    </div>
  )
}

export default CardWithCircleIcon
