import * as React from "react"
import { Typography, Grid } from "@material-ui/core"

import { vendors } from "./data"
import { useStyles } from "./styles"

const title = "Expertise across asset classes, business functions, and investment vehicle types"
const description = "We offer a holistic and unbiased approach to the vendor landscape. When implementing any system or vendor solution, we start by understanding your unique business needs, then draw on our vast experience with a number of vendors to configure the system(s) to streamline your operations with the ultimate goal of creating accurate and timely data."

const Vendors = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {description}
        </Typography>
        <Grid container className={classes.vehicleListContainer}>
        {vendors.map((item, index) => (
          <Grid item xs={6} sm={4} md={2} lg={2} xl={2}
            key={`vehicle-item-${index}`}
            className={classes.item}
          >
            <img src={item.image} alt={item.title} className={classes.logoImg} />
          </Grid>
        ))}
        </Grid>
      </div>
    </div>
  )
}

export default Vendors
